import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import {mediaBaseURL} from "../../utils/constant";


const {useRef, useState, useEffect} = React;

const LazyImage = ({src, alt, title, text}) => {
    const rootRef = useRef();

    const [isVisible, setIsVisible] = useState(false);
    const loading = 'https://www.tekrevol.com/Tek-Spinner.gif';

    useEffect(() => {
        const defaultIntersectionOptions = {
            threshold: 0,
            rootMargin: '0px',
        };

        const checkIntersections = entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            });
        };

        if (!isVisible) {
            const newIO = new IntersectionObserver(checkIntersections, defaultIntersectionOptions);
            newIO.observe(rootRef.current);
            return () => newIO.disconnect();
        }
    }, [isVisible]);
    return (
        <div className="tech_item">
            <div className="tech_img">
                <img src={`${mediaBaseURL}${isVisible ? src : loading}`} ref={rootRef}
                     className="img-fluid" alt={alt}/>
            </div>
        </div>
    );
};

function Technology({data}) {
    return (
        <div className="p-100 tekrevol_gray_bg ">
            <Container>
                <Row>
                    <Col xs={12} md={8} lg={8} xl={8} xxl={8}>
                        <div className="example" data-text={data?.technology_body_txt}>
                            <h2>{checkData(data, 'technology_title')}</h2>
                        </div>
                        <p>{checkData(data, 'technology_description')}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="tech_images">
                            {data?.technology_images?.map((el, i) => (
                                <LazyImage
                                    alt={el.title}
                                    src={el.image} key={i}

                                />
                            ))}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                        <div className="tech_listing">
                            <div className="tech_tit">
                                <h5>{checkData(data, 'tech_listing_title')}</h5>
                            </div>
                            <div className="tech_point">
                                <ul>
                                    {data?.tech_point?.map((value, i) => (
                                        <li key={i}>{value.title}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Technology;